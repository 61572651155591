const name = "TRANSACTIONS";

export const FETCH_TRANSACTIONS_ETHEREUM = `${name}/FETCH_TRANSACTIONS_ETHEREUM`;
export const FETCH_TRANSACTIONS_ETHEREUM_START = `${FETCH_TRANSACTIONS_ETHEREUM}/START`;
export const FETCH_TRANSACTIONS_ETHEREUM_END = `${FETCH_TRANSACTIONS_ETHEREUM}/END`;
export const FETCH_TRANSACTIONS_ETHEREUM_FAILURE = `${FETCH_TRANSACTIONS_ETHEREUM}/FAILURE`;
export const FETCH_TRANSACTIONS_ETHEREUM_SUCCESS = `${FETCH_TRANSACTIONS_ETHEREUM}/SUCCESS`;

export const FETCH_TRANSACTIONS_ERC20 = `${name}/FETCH_TRANSACTIONS_ERC20`;
export const FETCH_TRANSACTIONS_ERC20_START = `${FETCH_TRANSACTIONS_ERC20}/START`;
export const FETCH_TRANSACTIONS_ERC20_END = `${FETCH_TRANSACTIONS_ERC20}/END`;
export const FETCH_TRANSACTIONS_ERC20_FAILURE = `${FETCH_TRANSACTIONS_ERC20}/FAILURE`;
export const FETCH_TRANSACTIONS_ERC20_SUCCESS = `${FETCH_TRANSACTIONS_ERC20}/SUCCESS`;

export const FETCH_TRANSACTIONS_DNERO = `${name}/FETCH_TRANSACTIONS_DNERO`;
export const FETCH_TRANSACTIONS_DNERO_START = `${FETCH_TRANSACTIONS_DNERO}/START`;
export const FETCH_TRANSACTIONS_DNERO_END = `${FETCH_TRANSACTIONS_DNERO}/END`;
export const FETCH_TRANSACTIONS_DNERO_FAILURE = `${FETCH_TRANSACTIONS_DNERO}/FAILURE`;
export const FETCH_TRANSACTIONS_DNERO_SUCCESS = `${FETCH_TRANSACTIONS_DNERO}/SUCCESS`;

export const CREATE_SEND_TRANSACTION = `${name}/CREATE_SEND_TRANSACTION`;
export const CREATE_SEND_TRANSACTION_START = `${CREATE_SEND_TRANSACTION}/START`;
export const CREATE_SEND_TRANSACTION_END = `${CREATE_SEND_TRANSACTION}/END`;
export const CREATE_SEND_TRANSACTION_FAILURE = `${CREATE_SEND_TRANSACTION}/FAILURE`;
export const CREATE_SEND_TRANSACTION_SUCCESS = `${CREATE_SEND_TRANSACTION}/SUCCESS`;

export const CREATE_DEPOSIT_STAKE_TRANSACTION = `${name}/CREATE_DEPOSIT_STAKE_TRANSACTION`;
export const CREATE_DEPOSIT_STAKE_TRANSACTION_START = `${CREATE_DEPOSIT_STAKE_TRANSACTION}/START`;
export const CREATE_DEPOSIT_STAKE_TRANSACTION_END = `${CREATE_DEPOSIT_STAKE_TRANSACTION}/END`;
export const CREATE_DEPOSIT_STAKE_TRANSACTION_FAILURE = `${CREATE_DEPOSIT_STAKE_TRANSACTION}/FAILURE`;
export const CREATE_DEPOSIT_STAKE_TRANSACTION_SUCCESS = `${CREATE_DEPOSIT_STAKE_TRANSACTION}/SUCCESS`;

export const CREATE_WITHDRAW_STAKE_TRANSACTION = `${name}/CREATE_WITHDRAW_STAKE_TRANSACTION`;
export const CREATE_WITHDRAW_STAKE_TRANSACTION_START = `${CREATE_WITHDRAW_STAKE_TRANSACTION}/START`;
export const CREATE_WITHDRAW_STAKE_TRANSACTION_END = `${CREATE_WITHDRAW_STAKE_TRANSACTION}/END`;
export const CREATE_WITHDRAW_STAKE_TRANSACTION_FAILURE = `${CREATE_WITHDRAW_STAKE_TRANSACTION}/FAILURE`;
export const CREATE_WITHDRAW_STAKE_TRANSACTION_SUCCESS = `${CREATE_WITHDRAW_STAKE_TRANSACTION}/SUCCESS`;

export const CREATE_SMART_CONTRACT_TRANSACTION = `${name}/CREATE_SMART_CONTRACT_TRANSACTION`;
export const CREATE_SMART_CONTRACT_TRANSACTION_START = `${CREATE_SMART_CONTRACT_TRANSACTION}/START`;
export const CREATE_SMART_CONTRACT_TRANSACTION_END = `${CREATE_SMART_CONTRACT_TRANSACTION}/END`;
export const CREATE_SMART_CONTRACT_TRANSACTION_FAILURE = `${CREATE_SMART_CONTRACT_TRANSACTION}/FAILURE`;
export const CREATE_SMART_CONTRACT_TRANSACTION_SUCCESS = `${CREATE_SMART_CONTRACT_TRANSACTION}/SUCCESS`;

export const FETCH_TRANSACTION = `${name}/FETCH_TRANSACTION`;
export const FETCH_TRANSACTION_START = `${FETCH_TRANSACTION}/START`;
export const FETCH_TRANSACTION_END = `${FETCH_TRANSACTION}/END`;
export const FETCH_TRANSACTION_FAILURE = `${FETCH_TRANSACTION}/FAILURE`;
export const FETCH_TRANSACTION_SUCCESS = `${FETCH_TRANSACTION}/SUCCESS`;

export const RESET = `${name}/RESET`;
